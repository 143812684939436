<template>
  <div class="wrapper">
    <h4>{{ titleName }}</h4>
    <el-form :model="form" ref="form" :rules="rules" label-width="150px">
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="街道编码" prop="parentAreaCode">
            <el-input v-model="form.parentAreaCode" placeholder="请输入街道编码" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="社区名称" prop="areaName">
            <el-input v-model="form.areaName" placeholder="请输入社区名称" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12" v-if="routeType === 'edit'">
          <el-form-item label="社区编码" prop="areaCode">
            <el-input v-model="form.areaCode" placeholder="请输入社区编码" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="routeType === 'edit'">
          <el-form-item label="排序" prop="areaSort">
            <el-input v-model="form.areaSort" placeholder="请输入排序" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center;">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AddOrEdit',
  data() {
    return {
      titleName: '',
      form: {},
      rules: {
        parentAreaCode: [{ required: true, message: '请输入街道编码', trigger: 'blur' }],
        areaName: [{ required: true, message: '请输入社区名称', trigger: 'blur' }],
        areaCode: [{ required: true, message: '请输入社区编码', trigger: 'blur' }],
        areaSort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
      },
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    },
    searchist() {
      var list = []
      var _list = []
      var _this = this
      this.seqList.map(function(item) {
        if (item.plateNo.indexOf(_this.plateNoId) != -1) {
          //然后把当前json添加到list数组中
          _list.push(item)
          list = _list
        }
      })
      return list
    },
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['saveCommunityInfo']),
    focusEvent1() {
      this.$refs.xDown1.showPanel()
    },
    getLeaveTime(data) {
      this.form.leaveTime = data
    },
    getFactTime(data) {
      this.form.factTime = data
    },
    initView() {
      if (this.routeType == 'add') {
        this.titleName = '添加社区'
      } else {
        this.titleName = '修改社区'
        this.itemData = JSON.parse(this.$route.query.item)
        //这里调用请求编辑时的详情接口渲染form表单数据
        this.form = {
          parentAreaCode: this.itemData.parentAreaCode,
          areaCode: this.itemData.areaCode,
          areaName: this.itemData.areaName,
          areaSort: this.itemData.areaSort,
        }
      }
    },
    submitEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {}
          params = this.form
          let server = 'saveCommunityInfo'
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: '保存成功',
                status: 'success',
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  h4 {
    height: 40px;
    line-height: 40px;
    color: #f26333;
    padding-left: 20px;
  }

  .vxe-form {
    padding: 25px 0;
    overflow: auto;
  }
  .my-dropdown1 {
    height: 200px;
    overflow: auto;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    padding: 0 8px;
    li {
      height: 32px;
      line-height: 32px;
      cursor: pointer;
    }
  }
}
</style>
